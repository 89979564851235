<template>
  <div class="order-activity main-cnt">
    <div class="title">订单列表</div>
    <div class="content">
      <common-table
        ref="tableRef"
        tableHeight="calc(100vh - 320px)"
        :ischeck="false"
        :filters="filters"
        :groupFilters="groupFilters"
        :ispaging="true"
        :needExport="true"
        :needPrint="true"
        :apiName="OrderApi.activeList"
        :columns="tableColumns"
        @detail="openDetailDialog"
        @projectChange="projectChange"
        @export="handleExport"
        @print="handlePrint"
      >
        <template #pay_status="{ row }">
          <span :style="'color:' + payStatusColors[row.pay_status]">{{
            payStatusOptions[row.pay_status]
          }}</span>
        </template>
        <template #work_status="{ row }">
          <span :style="'color:' + workStatusColors[row.work_status]">{{
            workStatusOptions[row.work_status]
          }}</span>
        </template>
        <template #startrRefund="{ row }">
          <template
            v-if="
              row.work_status == 3 &&
              row.pay_status == 2 &&
              authData.indexOf('o_789OalFvc0XbJEpL4DqAMnd5RmoC') != -1
            "
          >
            <el-button
              class="black-font-btn"
              @click="openStartrRefundDialog(row)"
              >发起退款</el-button
            >
          </template>
        </template>
        <template #sureRefund="{ row }">
          <template
            v-if="
              row.pay_status == 4 &&
              authData.indexOf('o_4WIZpegzLqUraKfYx5RD0S6buoGA') != -1
            "
          >
            <el-button class="theme-font-btn" @click="openSureRefundDialog(row)"
              >确认退款</el-button
            >
          </template>
        </template>
      </common-table>
    </div>
  </div>
  <ActivityDetailDialog ref="activityDetailRef"></ActivityDetailDialog>
  <RefundDialog ref="initRefundRef" @submit="handleInitRefund"></RefundDialog>
  <RefundDialog ref="sureRefundRef" @submit="handleSureRefund"></RefundDialog>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { BasicApi, OrderApi } from "@/plugins/api.js";
import ActivityDetailDialog from "../components/ActivityDetailDialog.vue";
import RefundDialog from "../components/RefundDialog.vue";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import {
  exportExcel,
  print,
  getPayStatusColors,
  getWorkStatusColors,
  getPayStatusOptions,
  getActiveStatusOptions,
} from "@/utils/common.js";
const store = useStore();
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const authData = ref([]);
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "sn",
    label: "订单号",
    color: "--text-third-color",
    minWidth: 250,
  },
  {
    prop: "ma_name",
    label: "活动名称",
    minWidth: 140,
  },
  {
    prop: "mat_first_name",
    prop2: "mat_second_name",
    type: "connect",
    label: "所属模块",
    color: "--text-third-color",
    connectText: "-",
    minWidth: 200,
  },
  {
    prop: "stime",
    prop2: "etime",
    type: "connect",
    label: "活动有效期",
    color: "--text-third-color",
    minWidth: 180,
    connectText: "至",
  },
  {
    prop: "buy_num",
    label: "购买数量",
    color: "--theme-color",
    minWidth: 80,
  },
  {
    prop: "pay_money",
    label: "支付金额",
    minWidth: 80,
  },
  {
    prop: "refund_money",
    label: "退款金额",
    minWidth: 80,
    color: "--text-third-color",
  },
  {
    prop: "payway_name",
    label: "支付方式",
    minWidth: 80,
  },
  {
    prop: "pay_status",
    label: "支付状态",
    color: "--text-third-color",
    type: "customRender",
    minWidth: 90,
  },
  {
    prop: "work_status",
    label: "业务状态",
    color: "--text-third-color",
    type: "customRender",
    minWidth: 80,
  },
  {
    prop: "pay_time",
    label: "支付时间",
    color: "--text-third-color",
    minWidth: 150,
  },
  {
    prop: "source_text",
    label: "来源渠道",
    color: "--text-third-color",
    minWidth: 120,
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 200,
    bottons: [
      {
        name: "发起退款",
        action: "startrRefund",
        type: "customRender",
      },
      {
        name: "确认退款",
        action: "sureRefund",
        type: "customRender",
      },
      {
        name: "详情",
        action: "detail",
        token: "o_4nV7fxhCCLHXpc7o6705NprxT4cs",
      },
    ],
  },
]);

const groupFilters = ref([
  {
    filterType: "select",
    name: "source",
    value: "",
    label: "购买渠道",
    options: [],
    val: "id",
    lab: "name",
  },
  {
    filterType: "select",
    name: "pay_status",
    value: 2,
    label: "支付状态",
    options: [],
    val: "value",
    lab: "label",
    otherOptionsName: "work_status",
    showOtherOptionsValue: 2,
  },
  {
    filterType: "multipleSelect",
    name: "work_status",
    value: [],
    label: "业务状态",
    options: [],
    val: "value",
    lab: "label",
  },
  {
    filterType: "partDaterange",
    name1: "stime",
    value1: "",
    name2: "etime",
    value2: "",
    label: "支付时间",
  },
]);
const filters = ref([
  {
    filterType: "search",
    name: "keywords",
    value: "",
    placeholder: "搜索订单号、活动名称、会员姓名、手机号",
  },
  {
    filterType: "select",
    name: "pid",
    value: "",
    placeholder: "请选择项目",
    options: [],
    lab: "p_name",
    val: "p_id",
    action: "projectChange",
  },
  {
    filterType: "select",
    name: "ma_id",
    value: "",
    placeholder: "请选择活动",
    options: [],
    lab: "ma_name",
    val: "ma_id",
  },
]);
/** 项目列表 */
const projectOptions = ref([]);
/** 获取项目数据 */
const getProjectData = () => {
  BasicApi.projectSelect().then((res) => {
    if (res.Code === 200) {
      filters.value[1].options = res.Data ? res.Data : [];

      projectOptions.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取项目数据失败！";
      ElMessage.error(msg);
    }
  });
};
/** 项目选择更改 */
const projectChange = (obj) => {
  filters.value[2].value = "";
  filters.value[2].options = [];
  tableRef.value.tableLoad();
  if (obj.value) {
    BasicApi.getAllActiveData({
      p_id: obj.value,
    }).then((res) => {
      if (res.Code === 200) {
        filters.value[2].options = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取数据失败！";
        ElMessage.error(msg);
      }
    });
  }
};
const payStatusOptions = ref(getPayStatusOptions());
const payStatusColors = ref(getPayStatusColors());
const workStatusOptions = ref(getActiveStatusOptions());
const workStatusColors = ref(getWorkStatusColors());
const payStatusLabelArr = ref([]);
function getPayStatusLabelArr() {
  for (var key in payStatusOptions.value) {
    payStatusOptions.value[key] &&
      payStatusLabelArr.value.push({
        label: payStatusOptions.value[key],
        value: +key,
      });
  }
  groupFilters.value[1].options = payStatusLabelArr.value;
}
const workStatusLabelArr = ref([]);
function getWorkStatusLabelArr() {
  for (var key in workStatusOptions.value) {
    workStatusOptions.value[key] &&
      workStatusLabelArr.value.push({
        label: workStatusOptions.value[key],
        value: +key,
      });
  }
  groupFilters.value[2].options = workStatusLabelArr.value;
}
function getSourceOptions() {
  BasicApi.orderSourceOptions({}).then((res) => {
    if (res.Code === 200) {
      groupFilters.value[0].options = res.Data;
    } else {
      let msg = res.Message ? res.Message : "获取订单来源数据失败！";
      ElMessage.error(msg);
    }
  });
}
/** 表格对象 */
const tableRef = ref(null);
onMounted(() => {
  getProjectData();
  getPayStatusLabelArr();
  getWorkStatusLabelArr();
  getSourceOptions();
  tableRef.value.tableLoad();
});

const activityDetailRef = ref(null);
function openDetailDialog(row) {
  activityDetailRef.value.openDialog(row.sn, {
    payStatus: {
      options: payStatusOptions.value,
      colors: payStatusColors.value,
    },
  });
} // 发起退款
const initRefundRef = ref(null);
const currentRefundSn = ref("");
const openStartrRefundDialog = (row) => {
  currentRefundSn.value = row.sn;
  initRefundRef.value.openDialog("发起退款", row.pay_money);
};
function handleInitRefund(data) {
  const parmas = {
    sn: currentRefundSn.value,
    ...data,
  };
  OrderApi.initRefund(parmas).then((res) => {
    initRefundRef.value.closeDialogLoading();
    if (res.Code === 200) {
      initRefundRef.value.closeDialog();
      tableRef.value.tableLoad();
      ElMessage.success("操作成功");
    } else {
      let msg = res.Message ? res.Message : "操作失败！";
      ElMessage.error(msg);
    }
  });
} // 确认退款
const sureRefundRef = ref(null);
function openSureRefundDialog(row) {
  currentRefundSn.value = row.sn;
  sureRefundRef.value.openDialog(
    "确认退款",
    row.pay_money,
    row.confirm_refund_money
  );
}
function handleSureRefund(data) {
  const parmas = {
    sn: currentRefundSn.value,
    ...data,
  };
  OrderApi.sureRefund(parmas).then((res) => {
    sureRefundRef.value.closeDialogLoading();
    if (res.Code === 200) {
      sureRefundRef.value.closeDialog();
      tableRef.value.tableLoad();
      ElMessage.success("操作成功");
    } else {
      let msg = res.Message ? res.Message : "操作失败！";
      ElMessage.error(msg);
    }
  });
}

const propertiesConfig = ref([
  { field: "sn", displayName: "订单号" },
  { field: "ma_name", displayName: "活动名称" },
  { field: "moduleTitle", displayName: "所属模块" },
  { field: "validity", displayName: "活动有效期" },
  { field: "buy_num", displayName: "购买数量" },
  { field: "pay_money", displayName: "支付金额" },
  { field: "refund_money", displayName: "退款金额" },
  { field: "payway_name", displayName: "支付方式" },
  { field: "pay_status_text", displayName: "支付状态" },
  { field: "status_text", displayName: "业务状态" },
  { field: "pay_time", displayName: "支付时间" },
  { field: "source_text", displayName: "购买渠道" },
]);
function processData(data) {
  data.length &&
    data.forEach((el) => {
      el.moduleTitle = el.mat_first_name + "-" + el.mat_second_name;
      el.validity =
        el.stime === el.etime ? el.stime + "当天" : el.stime + "至" + el.etime;
    });
  return data;
}
function handlePrint(data) {
  print("活动订单", processData(data), propertiesConfig.value);
}
function handleExport(data) {
  try {
    exportExcel("活动订单", processData(data), propertiesConfig.value);
    ElMessage.success({
      message: "导出成功！",
    });
  } catch (e) {
    ElMessage.error({
      message: "导出失败！",
    });
  }
}
</script>
<style lang="scss">
.order-activity {
  font-family: "Source Han Sans CN";
  .search-input {
    width: 380px !important;
  }
  .el-input-group__append {
    border: none;
    background-color: var(--search-uncheck-bg-color) !important;
    color: var(--text-gray-color);
  }
  .content {
    padding: 15px 20px 20px;
  }
  .font-gray {
    color: var(--text-gray-color);
  }
  .font-999 {
    color: var(--text-third-color);
  }
  .refund-money {
    width: 100%;
    padding: 20px 0;
    background-color: var(--theme-bg-color);
    text-align: center;
    border-radius: 10px;
    margin-bottom: 20px;
    color: var(--text-gray-color);
    > div {
      color: var(--text-color);
      font-weight: bold;
      font-size: 16px;
    }
  }
}
</style>
