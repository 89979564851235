<template>
  <div class="activity-order-detail">
    <w-dialog
      ref="detailDialogRef"
      title="活动订单详情"
      width="64%"
      btnWidth="140px"
      top="10vh"
      :hideFooter="true"
      class="order-detail"
    >
      <el-descriptions :column="3" class="basic-info" border>
        <el-descriptions-item
          v-for="(el, index) in basicInfo"
          :key="index"
          :label="el.label"
          ><span
            :style="{
              color: el.color ? el.color[el.value] : '',
            }"
          >
            {{ el.text ? el.text[el.value] : el.value }}</span
          ></el-descriptions-item
        >
      </el-descriptions>
      <div class="font-999">子订单信息</div>
      <common-table
        ref="peopleTableRef"
        :ischeck="false"
        :ispaging="false"
        :tableData="tableData"
        :columns="peopleTableColumns"
      >
        <template #sn="{ row }">
          <el-link type="primary" @click="openDetailDialog(row)">{{
            row.sn
          }}</el-link>
        </template>
        <template #sub_work_status="{ row }">
          <span
            :style="
              'color:' + allStatusData.workStatus.colors[row.sub_work_status]
            "
            >{{ allStatusData.workStatus.options[row.sub_work_status] }}</span
          >
        </template>
      </common-table>
      <br />
      <div class="font-999">退款明细</div>
      <common-table
        ref="refundTableRef"
        :ischeck="false"
        :ispaging="false"
        :tableData="refundData"
        :columns="refundTableColumns"
      >
      </common-table>
    </w-dialog>
    <HotelDetailDialog ref="hotelDetailRef"></HotelDetailDialog>
    <TicketDetailDialog ref="ticketDialogRef"></TicketDetailDialog>
  </div>
</template>

<script>
import { ref, computed, watch, nextTick } from "vue";
import { OrderApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import HotelDetailDialog from "./HotelDetailDialog.vue";
import TicketDetailDialog from "./TicketDetailDialog.vue";
import {
  getWorkStatusColors,
  getHotelWorkStatusOptions,
} from "@/utils/common.js";
export default {
  emits: ["submit"],
  components: { TicketDetailDialog, HotelDetailDialog },
  setup() {
    const workStatusOptions = ref(getHotelWorkStatusOptions());
    const workStatusColors = ref(getWorkStatusColors());
    const detailDialogRef = ref(null);
    const basicInfo = ref({});
    const detailInfo = ref({});
    const store = useStore();
    const menuTokens = computed(() => store.state.menuToken.menuTokens);
    const authData = ref([]);
    const refundData = ref([]);
    const authInfo = ref({});
    watch(
      () => menuTokens.value,
      (data) => {
        if (data.length) {
          authData.value = data;
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
    const tableData = ref([]);
    const peopleTableColumns = ref([
      {
        prop: "sn",
        label: "子订单号",
        minWidth: 220,
        type: "customRender",
      },
      {
        prop: "sub_name",
        label: "商品名称",
      },
      {
        prop: "chlid_type",
        label: "订单类别",
      },
      {
        prop: "sub_num",
        label: "购买数量",
        color: "--text-third-color",
      },
      {
        prop: "sub_money",
        label: "原价金额",
        color: "--text-third-color",
      },
      {
        prop: "sub_sdate",
        label: "使用期限",
        color: "--text-third-color",
      },
      {
        prop: "sub_work_status",
        label: "业务状态",
        type: "customRender",
      },
    ]);

    function getDetail(type) {
      OrderApi.activeDetail({ sn: snId.value }).then((res) => {
        if (res.Code === 200) {
          tableData.value = res.Data.childOrder;
          refundData.value = res.Data.refund || [];
          detailInfo.value = res.Data;
          basicInfo.value = [
            {
              label: "活动名称",
              value: detailInfo.value.ma_name
                ? detailInfo.value.ma_name +
                  "×" +
                  detailInfo.value.buy_num +
                  "份"
                : "-",
            },

            {
              label: "所属模块",
              value: detailInfo.value.mat_first_name
                ? detailInfo.value.mat_second_name
                  ? detailInfo.value.mat_first_name +
                    "-" +
                    detailInfo.value.mat_second_name
                  : detailInfo.value.mat_first_name
                : "-",
            },

            {
              label: "所属项目",
              value: detailInfo.value.p_name ? detailInfo.value.p_name : "-",
            },
            {
              label: "订单号码",
              value: detailInfo.value.sn ? detailInfo.value.sn : "-",
            },
            {
              label: "购买渠道",
              value: detailInfo.value.source_text
                ? detailInfo.value.source_text
                : "-",
            },
            {
              label: "支付方式",
              value: detailInfo.value.payway_name
                ? detailInfo.value.payway_name
                : "-",
            },
            {
              label: "下单时间",
              value: detailInfo.value.ctime ? detailInfo.value.ctime : "-",
            },
            {
              label: "支付金额",
              value: detailInfo.value.pay_money
                ? detailInfo.value.pay_money + "元"
                : "-",
            },
            {
              label: "退款金额",
              value: detailInfo.value.refund_money
                ? detailInfo.value.refund_money + "元"
                : "-",
            },
            {
              label: "支付时间",
              value: detailInfo.value.pay_time
                ? detailInfo.value.pay_time
                : "-",
            },

            {
              label: "支付状态",
              value: detailInfo.value.pay_status
                ? detailInfo.value.pay_status
                : "-",
              color: allStatusData.value?.payStatus?.colors,
              text: allStatusData.value?.payStatus?.options,
            },
            {
              label: "订单状态",
              value: detailInfo.value.work_status
                ? detailInfo.value.work_status
                : "-",
              color: allStatusData.value?.workStatus?.colors,
              text: allStatusData.value?.workStatus?.options,
            },
            {
              label: "活动日期",
              value: detailInfo.value.stime
                ? detailInfo.value.stime + "至" + detailInfo.value.etime
                : "-",
            },

            {
              label: "下单会员",
              value: detailInfo.value.m_realname
                ? detailInfo.value.m_mobile
                  ? detailInfo.value.m_realname +
                    "(" +
                    detailInfo.value.m_mobile +
                    ")"
                  : detailInfo.value.m_realname
                : "-",
            },
          ];
          type == 1 ? detailDialogRef.value.show() : "";
          nextTick(() => {
            if (tableData.value && tableData.value.length != 0) {
              peopleTableRef.value.tableLoad();
            }
            if (refundData.value.length != 0) {
              refundTableRef.value.tableLoad();
            }
          });
        } else {
          let msg = res.Message ? res.Message : "获取详情数据失败！";
          ElMessage.error(msg);
        }
      });
    }
    const snId = ref(null);
    const refundTableRef = ref(null);
    const refundTableColumns = ref([
      {
        prop: "refund_sn",
        label: "退款单号",
        minWidth: 140,
      },
      {
        prop: "refund_money",
        label: "退款金额",
      },
      {
        prop: "refund_payway_name",
        label: "退款方式",
      },
      {
        prop: "refund_confirm_user",
        label: "退款确认人",
      },
      {
        prop: "refund_confirm_time",
        label: "退款时间",
      },
      {
        prop: "refund_remark",
        label: "退款原因",
      },
    ]);

    const peopleTableRef = ref(null);
    // 酒店订单详情
    const hotelDetailRef = ref(null);
    const ticketDialogRef = ref(null);
    function openDetailDialog(row) {
      if (row.sub_hpid) {
        hotelDetailRef.value.openDialog(row.sn, allStatusData.value);
      } else {
        ticketDialogRef.value.openDialog(row.sn, allStatusData.value);
      }
    }
    const allStatusData = ref({});
    function openDialog(id, data) {
      allStatusData.value = {
        payStatus: {
          colors: data.payStatus.colors,
          options: data.payStatus.options,
        },
        workStatus: {
          colors: workStatusColors,
          options: workStatusOptions,
        },
      };
      snId.value = id;
      getDetail(1);
    }
    function closeDialog() {
      detailDialogRef.value.close();
    }

    return {
      closeDialog,
      basicInfo,
      detailDialogRef,
      openDialog,
      detailInfo,
      authInfo,
      authData,
      peopleTableRef,
      tableData,
      peopleTableColumns,
      openDetailDialog,
      ticketDialogRef,
      hotelDetailRef,
      refundTableRef,
      allStatusData,
      refundData,
      refundTableColumns,
    };
  },
};
</script>

<style lang="scss">
.activity-order-detail {
  .order-detail {
    .el-dialog__body {
      padding-bottom: 200px;
    }
    .basic-info {
      border-radius: 4px;
      margin-bottom: 30px;
      padding: 16px 30px 12px 30px;
      background-color: var(--search-uncheck-bg-color);
      .el-descriptions__body {
        border: none;
        background-color: var(--search-uncheck-bg-color);
        .el-descriptions__label,
        .el-descriptions__content {
          border: 1px solid transparent;
          background-color: var(--search-uncheck-bg-color);
        }
        .el-descriptions__label {
          width: 64px;
          color: var(--text-gray-color);
          font-weight: normal;
        }
        .el-descriptions__cell {
          padding: 0 0 4px 0;
        }
        .el-descriptions__content {
          color: var(--text-color);
          min-width: 100px;
        }
      }
    }
    .refund-desc {
      > span {
        color: var(--text-third-color);
      }
      .info {
        background-color: var(--search-uncheck-bg-color);
        padding: 16px 30px;
        border-radius: 4px;
        color: var(--text-gray-color);
      }
    }
  }
}
</style>
